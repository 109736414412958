/* eslint-disable */
<template>
    <v-dialog
        persistent
        max-width="400"
        v-model="dialog">
        <v-card>
            <v-card-title>
                Add New Worker
            </v-card-title>
            <v-card-text>
                <v-row
                    dense
                    v-if="$_.size(errors) > 0">
                    <v-col
                        v-for="(item, key) in errors"
                        :key="key"
                        cols="auto">
                        <v-chip
                            color="error">
                            {{item}} is required
                        </v-chip>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="worker.name"
                            hide-details="auto"
                            outlined
                            label="Worker Name">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="worker.color"
                            v-mask="mask"
                            hide-details
                            class="ma-0 pa-0"
                            outlined>
                            <template v-slot:append>
                                <v-menu
                                    v-model="menu"
                                    top
                                    nudge-bottom="105"
                                    nudge-left="16"
                                    :close-on-content-click="false">
                                    <template v-slot:activator="{ on }">
                                        <div
                                            :style="swatchStyle"
                                            v-on="on"/>
                                    </template>
                                    <v-card>
                                        <v-card-text class="pa-0">
                                            <v-color-picker
                                                v-model="worker.color"
                                                flat/>
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn
                    color="error"
                    @click="dialog = false">
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    @click="saveWorker()">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mask} from 'vue-the-mask'

    export default {
        data() {
            return {
                dialog: false,
                menu: false,
                mask: '!#XXXXXX',
                worker: {
                    id: 0,
                    name: '',
                    color: '#48b84d'
                },
                errors: []
            }
        },
        computed: {
            swatchStyle() {
                const {worker, menu} = this;
                return {
                    edit: false,
                    backgroundColor: worker.color,
                    cursor: 'pointer',
                    height: '30px',
                    width: '30px',
                    borderRadius: menu ? '50%' : '4px',
                    transition: 'border-radius 200ms ease-in-out'
                }
            }
        },
        directives: {mask},
        methods: {
            addWorker(data) {
                this.edit = data.edit ? data.edit : false;
                if (data.worker && this.$_.size(data.worker) > 0) {
                    this.worker = data.worker
                } else {
                    this.worker = this.defaultWorker()
                }
                this.dialog = true
            },
            saveWorker() {
                let _this = this
                this.errors = []
                this.worker.userId = this.user.id
                this.$_.forEach(this.worker, function (value, key) {
                    if (!value) {
                        _this.errors.push(key)
                    }
                });
                if (this.$_.size(this.errors) === 0) {
                    if (this.edit) {
                        this.$store.dispatch('updateWorker', this.worker)
                    } else {
                        this.worker.created = Date.now()
                        this.$store.dispatch('addWorker', this.worker)
                    }
                    this.dialog = false
                }
            },
            defaultWorker() {
                return {
                    name: '',
                    color: '#48b84d'
                }
            }
        },
        created() {
            this.$EventBus.$on('addWorker', (data) => this.addWorker(data))
        }
    }
</script>