<template>
    <v-app>
        <v-navigation-drawer
            dark
            class="primary"
            v-model="drawer"
            app>
            <v-container>
                <v-row>
                    <v-col>
                        <h3 class="font-weight-regular white--text">
                            Pool<span class="warning--text">+</span> Browser Miner
                        </h3>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>                     
            <v-list
                dense
                nav>
                <router-link
                    v-for="item in appLinks"
                    :key="item.title"
                    :to="item.route ? item.route : '#'">
                    <v-list-item link>
                        <v-list-item-title>
                            <v-icon>
                                {{ item.icon }}
                            </v-icon>
                            {{ item.title }}
                        </v-list-item-title>
                    </v-list-item>
                </router-link>
            </v-list>            
            <template v-slot:append>
                <v-row
                    v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
                    justify="center">
                    <v-col cols="auto">
                        <v-img
                            class="mb-5"
                            width="200px"
                            height="200px"
                            src="./assets/logo.png">
                        </v-img>
                    </v-col>
                </v-row>
                <v-row v-else>    
                    <v-col>
                        <v-card
                            rounded
                            flat
                            class="grey darken-4 mx-3">
                            <v-card-text>
                                <p class="mb-0">
                                    Main Website Links
                                </p>
                                <v-list
                                    dense
                                    nav>
                                    <v-list-item
                                        link
                                        v-for="(item, key) in navLinks"
                                        :key="key"
                                        :href="item.link"
                                        target="_blank">
                                        <v-list-item-title>
                                            {{item.title}}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>            
        </v-navigation-drawer>
        <v-app-bar
            class="bg-headbackground"
            app>
            <v-app-bar-nav-icon @click="drawer = !drawer">
            </v-app-bar-nav-icon>
            <template v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm">
                <template v-for="(item, key) in navLinks">
                    <v-menu
                        v-if="item.subMenu"
                        :key="key"
                        open-on-hover
                        offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                text
                                :href="item.link"
                                v-bind="attrs"
                                v-on="on">
                                {{item.title}}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(item, index) in item.subMenu"
                                :key="index"
                                :href="item.link">
                                <v-list-item-title>
                                    {{ item.title }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn
                        v-else
                        text
                        :key="key"
                        :href="item.link"
                        target="_blank">
                        {{item.title}}
                    </v-btn>
                </template>
            </template>        
            <v-spacer></v-spacer>
            <!-- <v-item-group>
                <v-row
                    align="center"
                    dense>
                    <v-col cols="auto">
                        <v-chip>
                            <template v-if="minerActive">
                                {{hashRateSeconds}} H/s
                            </template>
                            <template v-else>
                                Miner Idle
                            </template>
                        </v-chip>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            :disabled="!minerActive"
                            size="x-small"
                            color="error"
                            @click="stopMiner()"
                            icon>
                            <v-icon>
                                mdi-stop
                            </v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            :disabled="minerActive"
                            size="x-small"
                            color="primary"
                            @click="startMiner()"
                            icon>
                            <v-icon>
                                mdi-play
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-item-group> -->
            <router-link
                v-if="isFreeAccount && isLoggedIn"
                :to="{name: 'signUp'}">
                <v-btn
                    :x-small="$vuetify.breakpoint.xs"
                    :small="$vuetify.breakpoint.sm"
                    outlined
                    color="warning">
                    Upgrade to Gold
                </v-btn>
            </router-link>
			<v-tooltip left>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						icon
						class="ml-5"
						v-bind="attrs"
						v-on="on"
						@click="$EventBus.$emit('showQuickStart', true)">
						<v-icon>
							mdi-help-box
						</v-icon>
					</v-btn>
				</template>
				<span>
					Help Guide
				</span>
			</v-tooltip>
            <v-menu
                :close-on-content-click="false"
                offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on">
                        <v-icon>
                            mdi-dots-vertical
                        </v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-list min-width="300">
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon :color="!isFreeAccount ? 'warning' : 'primary'">
                                    {{!isFreeAccount ? 'mdi-crown-circle' : 'mdi-account-circle'}}
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-row
                                    align="center"
                                    dense>
                                    <v-col>
                                        <h3
                                            class="font-weight-regular"
                                            :class="!isFreeAccount ? 'warning--text' : 'primary--text'">
                                            {{userName ? userName : 'Guest'}}
                                        </h3>
                                        <p
                                            v-if="!isFreeAccount"
                                            class="caption mb-0 warning--text">
                                            (Gold Membership)
                                        </p>
                                    </v-col>
                                    <v-col
                                        v-if="isLoggedIn"
                                        cols="auto">
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    x-small
                                                    fab
                                                    color="primary"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :loading="syncLoading"
                                                    :disabled="syncLoading"
                                                    @click="syncSubscription()">
                                                    <v-icon>
                                                        mdi-sync
                                                    </v-icon>
                                                    <template v-slot:loader>
                                                        <span class="custom-loader">
                                                            <v-icon light>mdi-sync</v-icon>
                                                        </span>
                                                    </template>
                                                </v-btn>
                                            </template>
                                            <span>Sync Subscription</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider>
                    </v-divider>
                    <v-list>
                        <template v-if="userToken">
                            <v-list-item
                                target="_blank"
                                href="https://monerominer.rocks/affiliate-area/">                                
                                <v-list-item-title class="warning--text">
                                    Earn More
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                target="_blank"
                                href="https://monerominer.rocks/membership-account/membership-invoice/">
                                <v-list-item-title>
                                    Invoices
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                target="_blank"
                                href="https://monerominer.rocks/membership-account/membership-billing/">
                                <v-list-item-title>
                                    Billing
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="logOutUser()">
                                <v-list-item-title>
                                    Logout
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                        <template v-else>
                            <router-link :to="{name: 'login'}">
                                <v-list-item>
                                    <v-list-item-title>
                                        Login
                                    </v-list-item-title>
                                </v-list-item>
                            </router-link>
                            <router-link :to="{name: 'signUp'}">
                                <v-list-item>
                                    <v-list-item-title>
                                        Get Started
                                    </v-list-item-title>
                                </v-list-item>
                            </router-link>
                        </template>
                        <v-list-item
                            target="_blank"
                            href="https://monerominer.rocks/contact">
                            <v-list-item-title>
                                Feedback/Issues?
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>
        </v-app-bar>
        <v-main>
            <router-view>
            </router-view>
        </v-main>
        <v-footer
            :app="!$vuetify.breakpoint.xs"
            class="navbackground">
            <v-row
                dense
                :justify="$vuetify.breakpoint.xs ? 'center' : 'start'"
                align="center">
                <v-col
                    cols="12"
                    sm="auto">
                    <p
                        :class="{'text-center': $vuetify.breakpoint.xs}"
                        class="caption mb-0 navbackground--text text--lighten-2">
                        © 2017 - 2024 MoneroMiner.Rocks. All Right Reserved.
                    </p>
                </v-col>
                <v-spacer>
                </v-spacer>
                <v-col cols="auto">
                    <v-btn
                        v-for="(item, key) in socialLinks"
                        :key="key"
                        icon
                        @click="socialClick(item)">
                        <v-icon>
                            {{item.icon}}
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-footer>
        <add-worker>
        </add-worker>
		<add-wallet>
		</add-wallet>
        <up-sell>
        </up-sell>
        <up-sell-gold>
        </up-sell-gold>
		<login-subscribe>
		</login-subscribe>
        <sync-message>
        </sync-message>
        <quick-start>
        </quick-start>
    </v-app>
</template>

<script>
    import AddWorker from "./components/workers/AddWorker"
	import AddWallet from "@/components/wallets/AddWallet"
    import UpSell from './components/modals/UpSell'
    import UpSellGold from "./components/modals/UpSellGold";
	import LoginSubscribe from "@/components/modals/LoginSubscribe"
    import SyncMessage from './components/modals/SyncSubscriptionMessage'
    import QuickStart from './components/quickStart/SideBar'
    import Miner from './mixins/Miner'

    export default {
        metaInfo: {
            title: 'Monero & Masari Browser Miner',
            htmlAttrs: {
                lang: 'en',
                amp: true
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' }
            ]
        },
        name: 'App',
        data() {
            return {
                drawer: !this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm,
                appLinks: [
                    {
                        title: 'Miner',
                        route: this.$router.resolve({name: 'miner'}).route.path,
                        icon: 'mdi-speedometer'
                    },
                    {
                        title: 'Workers',
                        route: this.$router.resolve({name: 'workers'}).route.path,
                        icon: 'mdi-account-hard-hat'
                    },
                    {
                        title: 'Wallets',
                        route: this.$router.resolve({name: 'wallets'}).route.path,
                        icon: 'mdi-wallet'
                    },
                    {
                        title: 'Reports',
                        route: this.$router.resolve({name: 'reports'}).route.path,
                        icon: 'mdi-chart-areaspline'
                    }
                ],                
                navLinks: [
                    {
                        title: 'Home',
                        link: this.$getConst('externalURL')
                    },
                    {
                        title: 'About',
                        link: this.$router.resolve({name: 'about'}).route.path,
                        subMenu: [
                            {
                                title: 'Miner FAQs',
                                link: this.$router.resolve({name: 'faqs'}).route.path 
                            },
                            {
                                title: 'Cryptocurrency Coins',
                                link: this.$router.resolve({name: 'coins'}).route.path
                            }
                        ]
                    },
                    {
                        title: 'Pool',
                        link: 'https://pool.rhinominer.rocks/'
                    },
                    {
                        title: 'Miner',
                        link: this.$router.resolve({name: 'miner'}).route.path
                    },
                    {
                        title: 'Mining Rigs',
                        link: this.$getConst('externalURL') + '/custom-mining-rigs',
                        subMenu: [
                            {
                                title: 'Request Miner Information',
                                link: this.$getConst('externalURL') + '/request-miner-information' 
                            },
                            {
                                title: 'Used Miners',
                                link: this.$getConst('externalURL') + '/shop'
                            }
                        ]
                    },
                    {
                        title: 'Monetization',
                        link: this.$getConst('externalURL') + '/miner-website-integration',
                        subMenu: [
                            {
                                title: 'JavaScript Miner',
                                link: this.$getConst('externalURL') + '/javascript-miner' 
                            },
                            {
                                title: 'Wordpress Plugin',
                                link: this.$getConst('externalURL') + '/rhino-miner-wordpress-plugin'
                            }
                        ]
                    },
                    {
                        title: 'News',
                        link: this.$getConst('externalURL') + '/news'
                    }
                ],
                socialLinks: [
                    {
                        icon: 'mdi-facebook',
                        link: 'https://www.facebook.com/monerminer.rocks'
                    },
                    {
                        icon: 'mdi-twitter',
                        link: 'https://twitter.com/crytpominerrock'
                    },
                    {
                        icon: 'mdi-instagram',
                        link: 'https://www.instagram.com/monerominer1/'
                    },
                    {
                        icon: 'mdi-discord',
                        link: 'https://discord.gg/RyRVMqVKGF'
                    }
                ],
                hashRate: this.$store.getters.getHashRate
            }
        },
        mixins: [
            Miner
        ],
        components: {
            AddWorker,
			AddWallet,
            UpSell,
            UpSellGold,
			LoginSubscribe,
            SyncMessage,
            QuickStart
        },
        computed: {
            hashRateSeconds() {
                return this.$_.last(this.minerChart.datasets[0].data);
            }
        },
        methods: {
            socialClick(item) {
                this.$gtag.event('click', {
                    'event_category': 'social',
                    'event_label': item.icon,
                    'value': true
                })
                this.$nextTick(() => {
                    window.open(item.link, '_blank').focus()
                })
            },
            async getGEOLocation() {
                if (!this.$session.has('country')) {
                    const API_KEY = 'f5f15a49b15e41098610a89ef01dc13e';
                    const API_URL = 'https://ipgeolocation.abstractapi.com/v1/?api_key=' + API_KEY;
                    const apiResponse = await fetch(API_URL);
                    const data = await apiResponse.json();
                    this.$session.set('country', data.country)
                }
            }
        },
        created() {
            this.$EventBus.$on('startMiner', () => this.startMiner())
            this.$EventBus.$on('stopMiner', () => this.stopMiner())
            this.getGEOLocation();
        }
    }
</script>

<style lang="scss">
    html {
        background: #121212;
    }
    a {
        text-decoration: none;
    }
    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }
    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
	.v-tour__target--highlighted {
		border-radius: 4px;
		box-shadow: 0 0 0 4px #2196f3!important;
		z-index: 4!important;
		max-width: 100%;
		margin: 0 auto;
	}
	.v-step {
		background: #2196f3!important;
		z-index: 4!important;
	}
</style>