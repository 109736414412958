export default {
    computed: {
        walletList() {
            return this.user.id ? this.userWallets : [
                {
                    name: 'Default Website Wallet',
                    coin: 'xmr',
                    address: '49UQZXXXXXXXXXXXXXXX',
                },
                {
                    name: 'Default Website Wallet',
                    coin: 'msr',
                    address: '5qAMgXXXXXXXXXXXXXXX',
                },
                {
                    name: 'Default Website Wallet',
                    coin: 'sumo',
                    address: 'SumooXXXXXXXXXXXXXXX',
                },
                {
                    name: 'Default Website Wallet',
                    coin: 'lthn',
                    address: 'iz4yeXXXXXXXXXXXXXXX'
                }
            ]
        }
    },
    methods: {
        addWallet(walletData = {}) {
            this.$gtag.event('click', {
                'event_category': 'miner_settings',
                'event_label': 'Add Wallet',
                'value': this.user.id ? this.user.id : 'Guest'
            })
            if (this.isFreeAccount && this.$_.size(this.userWallets) >= 4 && !walletData.edit) {
                this.$EventBus.$emit('showUpSell', 'add_wallet')
            } else if (!this.user.id) {
                this.$EventBus.$emit('showLoginSubscribe')
            } else {
                this.$EventBus.$emit('addWallet', walletData)
            }
        },
        deleteWallet(id) {
            this.$gtag.event('click', {
                'event_category': 'wallet',
                'event_label': 'Wallet Removed',
                'value': true
            })
            this.$store.dispatch('deleteWallet', id)
        }
    }
}