<template>
    <v-navigation-drawer
        v-model="drawer"
        right
		height="100vh"
		width="300"
		temporary
		fixed>
		<v-list-item
			dark
			class="secondary">
			<v-list-item-content>
				<v-list-item-title class="text-h6">
					Help Guide
				</v-list-item-title>
				<v-list-item-subtitle>
					Help guide for getting started
				</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
		<v-list-group
			v-for="(item, keyA) in qs"
			:key="keyA"
			active-class="secondary--text"
			:value="$route.name === keyA"
			:prepend-icon="item.icon">
			<template v-slot:activator>
				<v-list-item-title>
					{{item.title}}
				</v-list-item-title>
			</template>
			<v-divider>
			</v-divider>
			<v-expansion-panels
                class="rounded-0"
				accordion
				elevation-0>
				<v-expansion-panel
					v-for="(steps, keyB) in item.steps"
					:key="keyB"
					elevation-0>
					<v-expansion-panel-header>
						{{ steps.title }}
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<p class="caption" v-text="steps.description">
						</p>
						<v-btn
							small
							block
							text
							color="secondary"
							@click="startTour(keyA, steps.target)">
							<v-icon left>
								mdi-comment-arrow-left
							</v-icon>
							View
						</v-btn>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
			<v-btn
				block
				small
				color="secondary"
				elevation-0
				tile
				@click="startTour(keyA)">
				Start {{item.title}} Tour
			</v-btn>
		</v-list-group>
		<v-divider>
		</v-divider>
    </v-navigation-drawer>
</template>

<script>
    export default {
        data() {
            return {
                drawer: false
            }
        },
        mounted() {
            this.$EventBus.$on('showQuickStart', (value) => this.drawer = value)
        }
    }
</script>