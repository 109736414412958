<template>
    <v-dialog
        max-width="600"
        v-model="dialog">
        <v-card>
            <v-card-title class="warning">
                <v-icon>
                    mdi-star
                </v-icon>
                <v-spacer>
                </v-spacer>
                <v-icon @click="dialog = false">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row class="mt-3 mb-1">
                        <v-col>
                            <v-card outlined>
                                <v-row>
                                    <v-col class="text-center">
                                        <h3 class="mt-4">
                                            {{ $getConst('upsellText') }}
                                        </h3>
                                        <p class="mt-5 mb-0">
                                            The <span class="font-weight-bold warning--text">Gold</span> subscription has a lot to offer.  Not seeing ads is one of them!
                                        </p>
                                        <p>
                                            Click the button below to see all the benefits.
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="auto">
                            <v-btn
                                color="warning"
                                @click="dialog = false"
                                to="/sign-up">
                                Learn More
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        data() {
            return {
                dialog: false
            }
        },
        methods: {
            showUpsell() {
                this.dialog = true
            }
        },
        created() {
            this.$EventBus.$on('showUpSellGold', () => this.showUpsell())
        },
    }
</script>