/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/pool-miner',
        name: 'miner',
        meta: {
            title: 'Monero Masari Browser Miner'
        },
        component: () => import(/* webpackChunkName: "miner" */ '../views/Miner.vue')
    },
    {
        path: '/workers',
        name: 'workers',
        meta: {
            title: 'Browser Miner Workers'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "workers" */ '../views/Workers.vue')
    },
    {
        path: '/wallets',
        name: 'wallets',
        meta: {
            title: 'Browser Miner Wallets'
        },
        component: () => import(/* webpackChunkName: "wallets" */ '../views/Wallets.vue')
    },
    {
        path: '/reports',
        name: 'reports',
        meta: {
            title: 'Browser Miner Reports'
        },
        component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue')
    },
    {
        path: '/reports/wallet/:address/:coin',
        name: 'reportsWallet',
        meta: {
            title: 'Browser Miner Wallet Report'
        },
        component: () => import(/* webpackChunkName: "reportsWallet" */ '../views/ReportsWallet.vue'),
        props: true
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: 'Browser Miner Login'
        },
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
        path: '/sign-up',
        name: 'signUp',
        meta: {
            title: 'Browser Miner Sign Up'
        },
        component: () => import(/* webpackChunkName: "sign-up" */ '../views/SignUp.vue')
    },
    {
        path: '/browser-miner-faqs',
        name: 'faqs',
        component: () => import(/* webpackChunkName: "versions" */ '../views/MinerFaqs.vue')
    },
    {
        path: '/cryptocurrency-coins',
        name: 'coins',
        component: () => import(/* webpackChunkName: "coins" */ '../views/CryptocurrencyCoins.vue')
    },
    {
        path: '/about-browser-miner',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const scrollBehavior = function (to, from, savedPosition) {
    if (savedPosition) {
        // savedPosition is only available for popstate navigations.
        return savedPosition
    } else {
        const position = {}

        // scroll to anchor by returning the selector
        if (to.hash) {
            position.selector = to.hash
            position.offset = { y: 64 }

            // bypass #1number check
            if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
                return position
            }

            // if the returned position is falsy or an empty object,
            // will retain current scroll position.
            return false
        }

        return new Promise(resolve => {
            // check if any matched route config has meta that requires scrolling to top
            if (to.matched.some(m => m.meta.scrollToTop)) {
                // coords will be used if no selector is provided,
                // or if the selector didn't match any element.
                position.x = 0
                position.y = 64
            }

            // wait for the out transition to complete (if necessary)
            this.app.$root.$once('triggerScroll', () => {
                // if the resolved position is falsy or an empty object,
                // will retain current scroll position.
                resolve(position)
            })
        })
    }
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior
})

export default router
