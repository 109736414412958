import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { db } from '@/db'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {
            id: 0,
            token: '',
            email: null,
            name: null,
            subscription: 0
        },
        miner: {
            active: false,
            chart: {
                labels: [],
                datasets: [
                    {
                        label: 'Current Worker',
                        backgroundColor: '#48b84d',
                        data: []
                    }
                ]
            },
            coin: 'xmr',
            pool: '',
            worker: '',
            throttle: 0,
            poolData: {},
            walletApiData: {},
            walletData: {},
            logs: [],
            chartOn: true,
            logsOn: true
        },
        hashRate: 0,
        workers: [],
        wallets: []
    },
    getters: {
        getUser: state => {
            return state.user
        },
        getUserToken: state => {
            return state.user.token
        },
        getUserEmail: state => {
            return state.user.email
        },
        getUserName: state => {
            return state.user.name
        },
        getMinerActive: state => {
            return state.miner.active
        },
        getHashRate: state => {
            return state.hashRate
        },
        getMinerChart: state => {
            return state.miner.chart
        },
        getMinerCoin: state => {
            return state.miner.coin
        },
        getMinerPool: state => {
            return state.miner.pool
        },
        getMinerThrottle: state => {
            return state.miner.throttle
        },
        getMinerWorker: state => {
            return state.miner.worker
        },
        getUserWorkers: state => {
            return state.workers
        },
        getUserWallets: state => {
            return state.wallets
        },
        getPoolData: state => {
            return state.miner.poolData
        },
        getWalletApiData: state => {
            return state.miner.walletApiData
        },
        getWalletData: state => {
            return state.miner.walletData
        },
        getMinerLogs: state => {
            return state.miner.logs
        },
        getChartOn: state => {
            return state.miner.chartOn
        },
        getLogsOn: state => {
            return state.miner.logsOn
        }
    },
    mutations: {
        setUser(state, data) {
            state.user = data
        },
        setUserToken(state, data) {
            state.user.token = data
        },
        setUserEmail(state, data) {
            state.user.email = data
        },
        setUserName(state, data) {
            state.user.name = data
        },
        setUserSubscription(state, data) {
            state.user.subscription = data
        },
        setMinerActive(state, data) {
            state.miner.active = data
        },
        setHashRate(state, data) {
            state.hashRate = data
        },
        setMinerChart(state, data) {
            if (data.labels.length > 50) {
                data.labels.shift()
                data.datasets[0].data.shift()
            }
            state.miner.chart = data
        },
        setMinerCoin(state, data) {
            state.miner.coin = data
        },
        setMinerPool(state, data) {
            state.miner.pool = data
        },
        setMinerThrottle(state, data) {
            state.miner.throttle = data
        },
        setMinerWorker(state, data) {
            state.miner.worker = data
        },
        setUserWorkers(state, data) {
            state.workers = data
        },
        setUserWallets(state, data) {
            state.wallets = data
        },
        setPoolData(state, data) {
            state.miner.poolData = data
        },
        setWalletApiData(state, data) {
            state.miner.walletApiData = data
        },
        setWalletData(state, data) {
            state.miner.walletData = data
        },
        setMinerLogs(state, data) {
            state.miner.logs = data
        },
        setChartOn(state, data) {
            state.miner.chartOn = data
        },
        setLogsOn(state, data) {
            state.miner.logsOn = data
        },
        ...vuexfireMutations
    },
    actions: {
        bindWorkers: firestoreAction(({ bindFirestoreRef }, userId) => {
            return bindFirestoreRef('workers', db.collection('workers').where('userId', '==', userId))
        }),
        addWorker: firestoreAction((context, payload) => {
            return db.collection('workers').add(payload)
        }),
        deleteWorker: firestoreAction((context, payload) => {
            db.collection('workers')
                .doc(payload)
                .delete()
        }),
        updateWorker: firestoreAction((context, payload) => {
            db.collection('workers')
                .doc(payload.id)
                .set(payload)
        }),
        bindWallets: firestoreAction(({ bindFirestoreRef }, userId) => {
            return bindFirestoreRef('wallets', db.collection('wallets').where('userId', '==', userId))
        }),
        addWallet: firestoreAction((context, payload) => {
            return db.collection('wallets').add(payload)
        }),
        deleteWallet: firestoreAction((context, payload) => {
            db.collection('wallets')
                .doc(payload)
                .delete()
        }),
        updateWallet: firestoreAction((context, payload) => {
            db.collection('wallets')
                .doc(payload.id)
                .set(payload)
        })
    },
    modules: {},
})
