/* eslint-disable */
<template>
	<v-dialog
		persistent
		max-width="800"
		v-model="dialog">
		<v-card>
			<v-card-title>
				Add New Wallet
			</v-card-title>
			<v-card-text class="pt-3">
                <v-row
                    dense
                    v-if="$_.size(errors) > 0">
                    <v-col
                        v-for="(item, key) in errors"
                        :key="key"
                        cols="auto">
                        <v-chip
                            color="error">
                            {{item}} is required
                        </v-chip>
                    </v-col>
                </v-row>
				<v-row>
                    <v-col
                        cols="12"
                        sm="4">
                        <v-select
                            hide-details="auto"
                            v-model="wallet.coin"
                            :items="coins"
                            item-text="name"
                            item-value="slug"
                            label="Coin"
                            outlined
                            :menu-props="{ offsetY: true }">
                            <template v-slot:selection="{ item }">
                                <v-avatar
                                    class="mr-2"
                                    size="20">
                                    <img
                                        :src="getLogo(item.slug)"
                                        :alt="item.slug">
                                </v-avatar>
                                {{item.name}}
                            </template>
                            <template v-slot:item="{ item }">
                                <v-avatar
                                    class="mr-2"
                                    size="20">
                                    <img
                                        :src="getLogo(item.slug)"
                                        :alt="item.slug">
                                </v-avatar>
                                {{item.name}}
                            </template>
                        </v-select>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="wallet.name"
                            hide-details="auto"
                            outlined
                            label="Wallet Name">
                        </v-text-field>
                    </v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-text-field
							v-model="wallet.address"
							hide-details="auto"
							outlined
							label="Wallet Address">
						</v-text-field>
					</v-col>
				</v-row>
                <v-row dense>
                    <v-col>
                        <p class="mb-0">
                            <template v-if="wallet.coin">
                                Looking for a {{$_.toUpper(wallet.coin)}} address?  <a :href="walletLinks[wallet.coin]" target="_blank">Click here</a> to learn how to get one.
                            </template>
                            <template v-else>
                                Select a coin to learn how to get a coin wallet address.
                            </template>
                        </p>
                    </v-col>
                </v-row>
			</v-card-text>
			<v-card-actions>
				<v-spacer>
				</v-spacer>
				<v-btn
					color="error"
					@click="dialog = false">
					Cancel
				</v-btn>
				<v-btn
					color="primary"
					@click="saveWallet()">
					Save
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
    /**
     * @group Wallet
     * Component to add a wallet through the v-dialog component.
     */
    export default {
        data() {
            return {
                // @vuese
                // Show or hide the dialog.
                dialog: false,
                // @vuese
                // Wallet data.
                wallet: {
                    name: '',
                    coin: '',
                    address: ''
                },
                // @vuese
                // Log any errors.
                errors: [],
                walletLinks: {
                    'xmr': 'https://www.getmonero.org/downloads/',
                    'msr': 'https://getmasari.org/#downloads',
                    'sumo': 'https://www.sumokoin.org/',
                    'lthn': 'https://wallet.lt.hn/'
                }
            }
        },
        methods: {
            addWallet(data) {
                this.errors = [];
                this.edit = data.edit ? data.edit : false;
                if (data.wallet && this.$_.size(data.wallet) > 0) {
                    this.wallet = data.wallet
                } else {
                    this.wallet = this.defaultWallet()
                }
                this.dialog = true
            },
            saveWallet() {
                let _this = this
                this.errors = []
                this.wallet.userId = this.user.id
                this.wallet.created = Date.now()
                this.$_.forEach(this.wallet, function(value, key) {
                    if (!value) {
                        _this.errors.push(key)
                    }
                });
                if (this.$_.size(this.errors) === 0) {
                    if (this.edit) {
                        this.$store.dispatch('updateWallet', this.wallet)
                    } else {
                        this.$store.dispatch('addWallet', this.wallet)
                    }
                    this.dialog = false
                }
            },
            defaultWallet() {
                return {
                    name: '',
                    coin: '',
                    address: ''
                }
            }
        },
        created() {
            this.$EventBus.$on('addWallet', (data) => this.addWallet(data))
        }
    }
</script>