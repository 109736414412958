export default {
    data() {
        return {
            coins: [
                {
                    name: 'Monero (XMR)',
                    api: '',
                    slug: 'xmr'
                },
                {
                    name: 'Masari (MSR)',
                    api: 'masari',
                    slug: 'msr'
                },
                {
                    name: 'Sumokoin (SUMO)',
                    api: 'sumo',
                    slug: 'sumo'
                },
                {
                    name: 'Lethean (LTHN)',
                    api: 'lethean',
                    slug: 'lthn'
                }               
            ],
            defaultWallets: {
                xmr: '44g61FmBXCWEsDJABDcTswdWKVkwcU15PTgrzTtTavzf8MKQyD6r18mVhQGBCZsxcje1YCkHzs5gT528bWLBjMDH9diNJYB',
                msr: '5qAMguFgE8EG4rNHVkg5TJJjKnMBd99imeKrp9DMRiSzAvQLrbvsgyx4JETBNq42nSXiD4f8zknsCbYAxBpbUEMgLNrRKoR',
                sumo: 'Sumoo73eJcLYpHtMoMRry3483MnW45XqWGNnttamZzfd8hYGe4pLA7VcS5CxUphdonXEWqZyiuJXAUdRpU8YWJxJAApBjv1eAEk',
                lthn: 'iz4yetNoWkgBTEschpWsjpPpXBGuTsUF7VR1sMV39f9QZm5uAKTYcZpSmUYUTvVKkA2bdD1nGr5VD37XSwcebHnC1g1T9FiLZ'
            },
            minerError: [],
            poolInterval: null,
            walletInterval: null,
        }
    },
    computed: {
        minerCoin: {
            get() {
                return this.$store.getters.getMinerCoin
            },
            set(value) {
                this.clearMiner();
                this.$store.commit('setMinerCoin', value)
            }
        },
        minerPool: {
            get() {
                return this.$store.getters.getMinerPool
            },
            set(value) {
                this.clearMiner();
                this.$store.commit('setMinerPool', value)
            }
        },
        minerWorker: {
            get() {
                return this.$store.getters.getMinerWorker
            },
            set(value) {
                this.clearMiner();
                this.$store.commit('setMinerWorker', value)
            }
        },
        minerThrottle: {
            get() {
                if (this.isFreeAccount && this.isLoggedIn) {
                    return 40;
                }
                return this.$store.getters.getMinerThrottle
            },
            set(value) {
                this.clearMiner();
                this.$store.commit('setMinerThrottle', value)
            }
        },
        minerActive: {
            get() {
                return this.$store.getters.getMinerActive;
            },
            set(value) {
                this.$store.commit('setMinerActive', value)
            }
        },
        minerChart: {
            get() {
                return this.$store.getters.getMinerChart;
            },
            set(value) {
                this.$store.commit('setMinerChart', value)
            }
        },
        poolApiData: {
            get() {
                return this.$store.getters.getPoolData;
            },
            set(value) {
                this.$store.commit('setPoolData', value)
            }
        },
        walletApiData: {
            get() {
                return this.$store.getters.getWalletApiData;
            },
            set(value) {
                this.$store.commit('setWalletApiData', value)
            }
        },
        walletData: {
            get() {
                return this.$store.getters.getWalletData;
            },
            set(value) {
                this.$store.commit('setWalletData', value)
            }
        },
        minerLogs: {
            get() {
                return this.$store.getters.getMinerLogs
            },
            set(value) {
                this.$store.commit('setMinerLogs', value)
            }
        },
        chartOn: {
            get() {
                return this.$store.getters.getChartOn
            },
            set(value) {
                let gaEvent = 'Chart Off'
                if (value) {
                    gaEvent = 'Chart On'
                }
                this.$gtag.event('click', {
                    'event_category': 'miner',
                    'event_label': gaEvent
                })
                this.$store.commit('setChartOn', value)
            }
        },
        logsOn: {
            get() {
                return this.$store.getters.getLogsOn
            },
            set(value) {
                let gaEvent = 'Logs Off'
                if (value) {
                    gaEvent = 'Logs On'
                }
                this.$gtag.event('click', {
                    'event_category': 'miner',
                    'event_label': gaEvent
                })
                this.$store.commit('setLogsOn', value)
            }
        },
        interval() {
            return this.isFreeAccount ? 60000 : 10000
        },
        activeWorker() {
            let _this = this;
            let worker = {
                name: 'x',
                color: '#48b84d'
            }
            if (this.isLoggedIn && !this.isFreeAccount) {
                let workerObj = this.$_.find(this.userWorkers, function(o) { return o.id === _this.minerWorker; })
                worker = workerObj ? workerObj : worker
            }
            return worker;
        }
    },
    methods: {
        startMiner() {
            // Just for good measure.
            clearInterval(this.poolInterval)
            clearInterval(this.walletInterval)

            this.minerError = []
            let threads = -1
            let password = "x"
            let text
            let walletData = this.isLoggedIn ? this.walletData : this.defaultWallets[this.minerCoin]
            if (this.$_.isEmpty(walletData)) {
                this.minerError.push('Please select a wallet')
                alert('Please select a wallet')
            } else {
                this.$gtag.event('click', {
                    'event_category': 'miner',
                    'event_label': 'Miner Start',
                    'value': this.user.id ? this.user.id : 'Guest'
                    })
                if (this.isLoggedIn) {
                    if (!this.isFreeAccount) {
                        this.minerChart.datasets[0].backgroundColor = this.activeWorker.color;
                    }
                    text = 'Worker '+this.minerWorker+' is getting ready to start mining on '+this.minerPool+' with wallet '+this.walletData
                } else {
                    text = 'Demo worker is getting ready to start mining on '+this.minerPool
                }
                let log = {
                    color: 'primary',
                    action: 'Start',
                    text: text,
                    timestamp: new Date()
                }
                this.minerLogs.push(log);
                this.startMining(
                    this.minerPool,
                    walletData,
                    this.activeWorker.name,
                    threads,
                    password,
                    this.minerThrottle,
                    this.isFreeAccount
                );
                if (this.isLoggedIn) {
                    this.getApiWalletData()
                    if (this.minerCoin === 'msr' || this.minerCoin === 'sumo' || this.minerCoin === 'lthn') {
                        this.getPoolApiData()
                    } else {
                        this.poolApiData = []
                    }
                }
            }
        },
        stopMiner() {
            clearInterval(this.poolInterval)
            clearInterval(this.walletInterval)
            this.stopMining();
            let log = {
                color: 'error',
                action: 'Stop',
                text: 'Miner has stopped.',
                timestamp: new Date()
            }
            this.minerLogs.push(log);
            this.$gtag.event('click', {
                'event_category': 'miner',
                'event_label': 'Miner Stop',
                'value': this.user.id ? this.user.id : 'Guest'
            })
        },
        clearMiner() {
            if (this.minerActive) {
                this.$EventBus.$emit('stopMiner')
            }
        },
        getPoolApiData() {
            let api = ''
            if (this.minerCoin === 'msr') {
                api = 'https://api.hashvault.pro/v3/masari/stats'
            }
            if (this.minerCoin === 'sumo') {
                api = 'https://api.hashvault.pro/v3/sumokoin/stats'
            }
            if (this.minerCoin === "lthn") {
                api = "https://api.hashvault.pro/v3/lethean/stats";
            }
            this.poolInterval = setInterval(() => {
                this.$http.get(api).then((response) => {
                    if (response.data) {
                        this.poolApiData = response.data
                    }
                })
            }, this.interval)
        },
        getApiWalletData() {
            let api = ''
            if (this.minerCoin === 'msr') {
                api = 'https://api.hashvault.pro/v3/masari/wallet/'+this.walletData+'/stats?workers=true'
            } else if (this.minerCoin === 'sumo') {
                api = 'https://api.hashvault.pro/v3/sumokoin/wallet/'+this.walletData+'/stats?workers=true'
            } else if (this.minerCoin === 'lthn') {
                api = 'https://api.hashvault.pro/v3/lethean/wallet/'+this.walletData+'/stats?workers=true'
            } else {
                api = 'https://api.moneroocean.stream/miner/'+this.walletData+'/stats'
            }
            this.walletInterval = setInterval(() => {
                this.$EventBus.$emit('dataCardLoading', true)
                this.$http.get(api).then((response) => {
                    if (response.data) {
                        this.walletApiData = response.data
                        this.$nextTick(() => {
                            this.$EventBus.$emit('dataCardLoading', false)
                        })
                    }
                })
            }, this.interval)
        },
        getLogo(name) {
            return require('@/assets/'+name+'_logo.png')
        },
        roundNumber(value) {
            return this.$_.round(value, 8).toFixed(8)
        },
    }
};