import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		dark: true,
		themes: {
			light: {
				background: '#FFFFFF',
				navbackground: '#f5f5f5',
				surface: '#FFFFFF',
				primary: '#48b84d',
				secondary: '#2196f3',
				accent: '#ff5722',
				error: '#f44336',
				warning: '#ffc107',
				info: '#00bcd4',
				success: '#8bc34a'
			},
			dark: {
				navbackground: '#212121',
				primary: '#48b84d',
				secondary: '#2196f3',
				accent: '#ff5722',
				error: '#f44336',
				warning: '#ffc107',
				info: '#00bcd4',
				success: '#8bc34a'
			}
		}
	}
});
