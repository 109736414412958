const Constants = {
    externalURL: 'https://monerominer.rocks',
    apiHost: 'https://monerominer.rocks/wp-json',
    apiHashvault: 'https://api.hashvault.pro/v3/',
    pageTitle: 'Monero Masari Browser Miner',
    minerIdle: 'Miner Idle',
    upsellText: 'Join our exclusive community of miners for $15 a year'
}

Constants.install = function (Vue) {
    Vue.prototype.$getConst = (key) => {
        return Constants[key];
    }
};

export default Constants;