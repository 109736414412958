<template>
    <v-dialog
        max-width="700"
        v-model="dialog">
        <v-card>
            <v-card-title :class="status === 200 ? 'primary' : 'error'">
                {{status === 200 ? 'Success!' : 'Error!'}}
                <v-spacer>
                </v-spacer>
                <v-icon @click="dialog = false">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-text class="text-center pt-5">
                <v-row>
                    <v-col>
                        <p class="mb-0">
                            <template v-if="status === 200">
                                Success, your subscription has been updated.
                            </template>
                            <template v-else>
                                {{message}}
                            </template>
                        </p>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-btn
                            class="secondary"
                            @click="dialog = false">
                            Ok
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        data() {
            return {
                dialog: false,
                status: 0,
                message: ''
            }
        },
        methods: {
            showDialog(data) {
                this.status = data.status
                this.message = data.message ? data.message : ''
                this.dialog = true
            }
        },
        mounted() {
            this.$EventBus.$on('showSyncMessage', (data) => this.showDialog(data))
        }
    }
</script>