/* eslint-disable */
import moment from 'moment'

let miner = {
    data() {
        return {
            server: 'wss://f.xmrminingproxy.com:8181/',
            wasmSupported: (() => {
                try {
                    if (typeof WebAssembly === "object"
                        && typeof WebAssembly.instantiate === "function") {
                        const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
                        if (module instanceof WebAssembly.Module)
                            return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
                    }
                } catch (e) {
                }
                return false;
            })(),
            connected: 0,
            handshake: null,
            ws: null,
            job: null,
            workers: [],
            attempts: 1,
            broadCast: {},
            sendStack: [],
            receiveStack: [],
            throttleMiner: 0,
            logicalProcessors: null,
            address:
                '49UQZAR62kZBkDCcT8TA3ZhJU3d75FvQNAMmoXnpfNAD7m5YuKVhN6PW7dL3ktXWdz6xvJdigU44yd7MemFDA86ZU5UfGLc',
            hashChecking: false,
            lastRate: 0,
            hashRate: 0
        }
    },
    computed: {
        minerActive: {
            get() {
                return this.$store.getters.getMinerActive;
            },
            set(value) {
                this.$store.commit('setMinerActive', value)
            }
        },
        minerChart: {
            get() {
                return this.$store.getters.getMinerChart;
            },
            set(value) {
                this.$store.commit('setMinerChart', value)
            }
        },
        minerLogs: {
            get() {
                return this.$store.getters.getMinerLogs;
            },
            set(value) {
                this.$store.commit('setMinerLogs', value)
            }
        }
    },
    watch: {
        minerActive(active) {
            if (active) {
                this.hashChecking = setInterval(() => {


                    let miner = this.minerChart;
                    if (miner) {
                        miner.labels.push(moment().format('LTS'));
                        miner.datasets[0].data.push(this.hashRate)
                    }
                    this.minerChart = {
                        ...this.minerChart,
                        newProperty: miner,
                    };
                    this.hashRate = 0;
                }, 1000)
            } else {
                clearInterval(this.hashChecking)
            }
        },
        sendStack(value) {
            this.pushLog(value)
        },
        receiveStack(value) {
            this.pushLog(value)
        }
    },
    methods: {
        pushLog(value) {
            let log;
            switch (value[0].identifier) {
                case 'job':
                    log = {
                        color: 'pink',
                        action: 'Job',
                        text: 'There is a new job form the mining pool.  Job ID - ' + value[0].job_id,
                        timestamp: new Date()
                    }
                    break;
                case 'solved':
                    log = {
                        color: 'blue',
                        action: 'Job Solved',
                        text: 'Miner has solved a job for the pool.  ' + value[0].job_id,
                        timestamp: new Date()
                    }
                    break;
                case 'hashsolved':
                    log = {
                        color: 'green',
                        action: 'Hash Accepted',
                        text: 'Your hash was accepted by the pool.',
                        timestamp: new Date()
                    }
                    break;
                case 'error':
                    log = {
                        color: 'error',
                        action: 'Error',
                        text: value[0].param,
                        timestamp: new Date()
                    }
                    break;
                default:
            }
            if (log && this.$store.getters.getLogsOn) {
                this.minerLogs.push(log);
                if (this.minerLogs.length > 1000) {
                    this.minerLogs.shift()
                }
            }
        },
        startMining(pool, login, password = "", numThreads = -1, userid = "", throttle = 0, freeAccount = true) {
            if (!this.wasmSupported) return;

            this.stopMining();
            this.connected = 0;
            this.throttleMiner = throttle;
            if (!freeAccount) {
                this.server = 'wss://paid.xmrminingproxy.com/'
            } else {
                this.server = 'wss://ny1.xmrminingproxy.com/'
            }
            this.handshake = {
                identifier: "handshake",
                pool: pool,
                login: login,
                password: password,
                userid: userid,
                version: 8
            };

            this.startBroadcast(() => {
                this.addWorkers(numThreads);
                this.reconnector();
            });
        },
        stopMining() {
            this.connected = 3;

            if (this.ws != null) this.ws.close();
            this.deleteAllWorkers();
            this.job = null;

            this.minerActive = false;
            this.stopBroadcast();
        },
        deleteAllWorkers() {
            let i;
            if (this.workers) {
                for (i = 0; i < this.workers.length; i++) {
                    this.workers[i].terminate();
                }
            }
            this.workers = [];
        },
        reconnector() {
            if (this.connected !== 3 && (this.ws == null || (this.ws.readyState !== 0 && this.ws.readyState !== 1))) {
                // console.log("The WebSocket is not connected. Trying to connect.");
                this.attempts++;
                this.openWebSocket();
            }
            if (this.connected !== 3)
                setTimeout(this.reconnector, 10000 * this.attempts);
        },
        openWebSocket() {
            if (this.ws != null) {
                this.ws.close();
            }

            let _this = this;
            let splitted = this.server.split(";")
            let chosen = splitted[Math.floor(Math.random() * splitted.length)];
            this.ws = new WebSocket(chosen);
            this.ws.onmessage = this.onServerMsg;

            this.ws.onerror = function () {
                if (_this.connected < 2) _this.connected = 2;
                _this.job = null;
            }
            this.ws.onclose = function () {
                if (_this.connected < 2) _this.connected = 2;
                _this.job = null;
            }

            this.ws.onopen = function () {
                _this.ws.send((JSON.stringify(_this.handshake)));
                _this.attempts = 1;
                _this.connected = 1;
                _this.minerActive = true;
            }
        },
        startBroadcast(mining) {
            if (typeof BroadcastChannel !== "function") {
                mining();
                return;
            }
            this.stopBroadcast();
            this.broadCast.bc = new BroadcastChannel('channel');
            let _this = this;
            let number = Math.random();
            let array = [];
            let timerc = 0;
            let wantsToStart = true;

            array.push(number);
            this.broadCast.bc.onmessage = function (ev) {
                if (array.indexOf(ev.data) === -1) array.push(ev.data);
            }

            function checkShouldStart() {
                _this.broadCast.bc.postMessage(number);
                timerc++;
                if (timerc % 2 === 0) {
                    array.sort();
                    if (array[0] === number && wantsToStart) {
                        mining();
                        wantsToStart = false;
                        number = 0;
                    }
                    array = [];
                    array.push(number);
                }
            }

            this.broadCast.id = setInterval(checkShouldStart, 1000);
        },
        stopBroadcast() {
            if (this.broadCast && typeof this.broadCast.bc !== 'undefined') {
                this.broadCast.bc.close();
            }

            if (this.broadCast && typeof this.broadCast.id !== 'undefined') {
                clearInterval(this.broadCast.id);
            }
        },
        addWorker() {
            let _this = this;
            let newWorker = new Worker("/miner/worker.js");
            this.workers.push(newWorker);

            newWorker.onmessage = this.onWorkerMsg;
            setTimeout(function () {
                _this.informWorker(newWorker);
            }, 2000);
        },
        addWorkers(numThreads) {
            this.logicalProcessors = numThreads;

            if (numThreads === -1) {
                /* try to find a good value */
                try {
                    this.logicalProcessors = window.navigator.hardwareConcurrency;
                } catch (err) {
                    this.logicalProcessors = 4;
                }

                if (!((this.logicalProcessors > 0) && (this.logicalProcessors < 40)))
                    this.logicalProcessors = 4;
            }

            while (this.logicalProcessors-- > 0) this.addWorker();
        },
        informWorker(wrk) {
            let evt = {
                data: "wakeup",
                target: wrk
            };
            this.onWorkerMsg(evt);
        },
        onWorkerMsg(e) {
            let wrk = e.target;
            let _this = this;

            if (this.connected !== 1) {
                setTimeout(function () {
                    _this.informWorker(wrk);
                }, 2000);
                return;
            }

            if ((e.data) !== "nothing" && (e.data) !== "wakeup") {
                // we solved a hash. forward it to the server.
                let obj = JSON.parse(e.data);
                this.ws.send(e.data);
                this.sendStack.push(obj);
            }

            if (this.job === null) {
                setTimeout(function () {
                    _this.informWorker(wrk);
                }, 2000);
                return;
            }

            let jbthrt = {
                job: this.job,
                throttle: Math.max(0, Math.min(this.throttleMiner, 100))
            };
            wrk.postMessage(JSON.parse(JSON.stringify(jbthrt)));

            if ((e.data) !== "wakeup") {
                this.hashRate = this.hashRate += 1;
            }
        },
        onServerMsg(e) {
            let obj = JSON.parse(e.data);
            this.receiveStack.push(obj);

            if (obj.identifier === "job") this.job = obj;
        }
    }
}

export default miner