<template>
	<v-dialog
		max-width="700"
		v-model="dialog">
		<v-card>
			<v-card-title class="secondary">
				Limit Reached :(
				<v-spacer>
				</v-spacer>
				<v-icon @click="dialog = false">
					mdi-close
				</v-icon>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col class="text-center">
							<p class="mt-5 mb-0">
								You have reached your limit for this feature.  Get unlimited access with <span class="font-weight-bold">Pool+ Gold!</span>
							</p>
							<p>
								If you feel this is an error please visit the FAQs page for more information.
							</p>
						</v-col>
					</v-row>
					<v-row justify="center">
                        <v-col cols="auto">
                            <router-link :to="{name: 'faqs'}">
                                <v-btn
                                    @click="dialog = false"
                                    color="primary">
                                    FAQs
                                </v-btn>
                            </router-link>
                        </v-col>
						<v-col cols="auto">
							<router-link :to="{name: 'signUp'}">
								<v-btn
									@click="dialog = false"
									color="primary">
									Learn More
								</v-btn>
							</router-link>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialog: false
		}
	},
	methods: {
		showUpsell(button) {
			this.$gtag.event('view', {
				'event_category': 'modals',
				'event_label': 'Upsell',
				'value': button ? button : null
			})
			this.dialog = true
		}
	},
	created() {
		this.$EventBus.$on('showUpSell', (button) => this.showUpsell(button))
	},
}
</script>