export default {
    data() {
        return {
            qs: {
                miner: {
                    title: 'Miner',
                    icon: 'mdi-speedometer',
                    steps: [
                        {
                            title: 'Miner Settings',
                            description: `The miner settings is where you select your coin, pool, worker, and wallet for the miner.`,
                            target: '#qs-miner-settings',
                            content: `This is where you select the settings for the miner.`,
                            params: {
                                highlight: true
                            },
                            offset: -100
                        },
                        {
                            title: 'Miner Controls',
                            description: `The miner controls are used to start and stop the miner.  Once the miner is running the start button will be disabled and the stop button will be enabled.`,
                            target: '#qs-miner-controls',
                            content: `Controls to start and stop the miner are located here.`,
                            params: {
                                highlight: true
                            },
                            offset: -100
                        },
                        {
                            title: 'Hash Chart',
                            description: `The hash chart reports the hash rate for the current miner and worker.  The chart is colored with the color that is set on the worker for easy identification.`,
                            target: '#qs-miner-chart',
                            content: `Hash rate of the miner.  Only reports when the miner is active.`,
                            params: {
                                highlight: true
                            },
                            offset: -100
                        },
                        {
                            title: 'Wallet Statistics',
                            description: `The wallet statistics is the data sent back from the pool for the wallet address being used.  Shares are used by the pool to determine how to share the block reward when the pool finds a block.  The min payout is the min payout required by the pool before they make a payment to your wallet.`,
                            target: '#qs-miner-statistics',
                            content: `Wallet statistics reported from the pool with the wallet used.`,
                            params: {
                                highlight: true
                            },
                            offset: -100
                        },
                        {
                            title: 'Miner Logs',
                            description: `Miner logs are used to output what is going on with the miner.  If there is an error you will see it here.  You will also see when a new job is received, solved and accepted by the pool.`,
                            target: '#qs-miner-logs',
                            content: `Log output of the miner.  For troubleshooting, errors will display here.`,
                            params: {
                                highlight: true,
                                placement: 'top'
                            },
                            offset: -100
                        }
                    ]
                }
            }
        }
    },
    methods: {
        startTour(name, target = undefined) {
            this.$EventBus.$emit('showQuickStart', false)
            if (this.$tours[name]) {
                this.$tours[name].stop()
            }
            if (this.$route.name !== name) {
                this.$router.push({name: name})
            }
            this.$nextTick(() => {
                if (target) {
                    target = this.$_.findIndex(this.qs[name].steps, (e) => {
                        return e.target === target
                    })
                }
                this.$tours[name].start(target)
            })

        }
    }
}