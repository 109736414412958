import firebase from 'firebase/app'
import 'firebase/firestore'


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDQ2bMkD3pu8usqwo_nSO9YRw3nLEeh4x8",
    authDomain: "miner-app-22f3b.firebaseapp.com",
    databaseURL: "https://miner-app-22f3b-default-rtdb.firebaseio.com",
    projectId: "miner-app-22f3b",
    storageBucket: "miner-app-22f3b.appspot.com",
    messagingSenderId: "749951752036",
    appId: "1:749951752036:web:04e0a0940bcb41483e8c71",
    measurementId: "G-N9WSEH15LE"
};
// Initialize Firebase
//firebase.initializeApp(firebaseConfig);
export const db = firebase
    .initializeApp(firebaseConfig)
    .firestore()

const { TimeStamp, GeoPoint } = firebase.firestore
export { TimeStamp, GeoPoint }