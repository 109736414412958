export default {
    data() {
        return {
            isPremium: false,
            syncLoading: false
        }
    },
    computed: {
        isLoggedIn() {
            return this.user.id
        },
        isFreeAccount() {
            return !this.isLoggedIn && !this.user.subscription || this.user.subscription === 4 || this.user.subscription === 0
        },
        user: {
            get() {
                return this.$store.getters.getUser;
            },
            set(value) {
                this.$store.commit('setUser', value)
            }
        },
        userToken: {
            get() {
                return this.$store.getters.getUserToken
            },
            set(value) {
                this.$store.commit('setUserToken', value)
            }
        },
        userEmail: {
            get() {
                return this.$store.getters.getUserEmail
            },
            set(value) {
                this.$store.commit('setUserEmail', value)
            }
        },
        userName: {
            get() {
                return this.$store.getters.getUserName
            },
            set(value) {
                this.$store.commit('setUserName', value)
            }
        },
        userWorkers: {
            get() {
                return this.$store.getters.getUserWorkers
            },
            set(value) {
                this.$store.commit('setUserWorkers', value)
            }
        },
        userWallets: {
            get() {
                return this.$store.getters.getUserWallets
            },
            set(value) {
                this.$store.commit('setUserWallets', value)
            }
        }
    },
    methods: {
        logOutUser() {
            this.user = {
                id: 0,
                token: '',
                email: null,
                name: null,
                subscription: 0
            }
            if (this.$cookies.isKey('user')) {
                this.userToken = this.$cookies.remove('user')
            }
            window.location.reload()
        },
        syncSubscription() {
            if (!this.userToken.token) {
                _this.$EventBus.$emit('showSyncMessage', {status: 404, message: 'Token is missing, please log out and log back in.'})
            }

            let _this = this;
            this.syncLoading = true;

            let headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.userToken.token
            }
            this.$http.post(this.$getConst('apiHost') + '/user/subscription', null, {headers}).then(function (response) {
                let subscription = response.data.id ? _this.$_.toInteger(response.data.id) : 0;
                _this.$store.commit('setUserSubscription', subscription)
                _this.$EventBus.$emit('showSyncMessage', {status: response.status})
                _this.syncLoading = false
            }).catch(error => {
                console.log(error)
                _this.$EventBus.$emit('showSyncMessage', {status: 404, message: 'Something wen\'t wrong.  Please log out and log back in.'})
                _this.syncLoading = false
            })
        },
        bindUserData() {
            if (this.user && this.user.id) {
                this.$store.dispatch('bindWallets', this.user.id)
                this.$store.dispatch('bindWorkers', this.user.id)
            }
        }
    }
}