import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import moment from 'moment'
import Axios from 'axios'
import VueGtag from "vue-gtag"
import VueMeta from 'vue-meta'
import VueTour from 'vue-tour'
import VueSession from 'vue-session'

require('vue-tour/dist/vue-tour.css')

import _ from 'lodash'

Object.defineProperty(Vue.prototype, '$_', {value: _})

import MinerData from './mixins/MinerData'
import UserData from './mixins/UserData'
import WorkerData from './mixins/Worker'
import WalletData from './mixins/Wallets'
import Versions from './mixins/Versions'
import QuickStart from './mixins/QuickStart'

Vue.mixin(MinerData)
Vue.mixin(UserData)
Vue.mixin(WorkerData)
Vue.mixin(WalletData)
Vue.mixin(Versions)
Vue.mixin(QuickStart)

import Constants from './constants/index'
import Ads from 'vue-google-adsense'

Vue.use(Constants)
Vue.use(require('vue-cookies'))
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)
Vue.use(VueTour)
Vue.use(require('vue-moment'))
Vue.use(VueSession)

Vue.$cookies.config('7d')

Vue.prototype.moment = moment
Vue.prototype.$EventBus = new Vue()
Vue.prototype.$http = Axios

Vue.config.productionTip = false

Vue.use(VueGtag, {
    config: {
        id: location.host !== 'dev.monerominer.rocks:8080' ? 'G-YLPGMFLGQ3' : 'G-5BF20FGSFR',
        params: {
            send_page_view: false
        }
    }
});

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
})

new Vue({
	router,
	store,
	vuetify,
	created() {
        let _this = this
		if (this.$cookies.isKey('user')) {
			this.user = this.$cookies.get('user')
		}
		this.bindUserData();
        router.afterEach(( to ) => {
            Vue.nextTick(function () {
                document.title = to.meta.title || _this.$getConst('pageTitle')
                if (_this.$gtag) {
                    _this.$gtag.pageview(to.path)
                }
            })
        })
	},
	render: h => h(App)
}).$mount('#app')
