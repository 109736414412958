export default {
    computed: {
        workerList() {
            return this.$_.size(this.userWorkers) > 0 && !this.isFreeAccount ? this.userWorkers : [
                {
                    name: 'x',
                    color: '#48b84d'
                }
            ]
        }
    },
    methods: {
        addWorker(workerData = {}) {
            this.$gtag.event('click', {
                'event_category': 'miner_settings',
                'event_label': 'Add Worker',
                'value': this.user.id ? this.user.id : 'Guest'
            })
            if (this.isFreeAccount) {
                this.$EventBus.$emit('showUpSell', 'add_worker')
            } else if (!this.user.id) {
                this.$EventBus.$emit('showLoginSubscribe')
            } else {
                this.$EventBus.$emit('addWorker', workerData)
            }
        },
        deleteWorker(id) {
            this.$gtag.event('click', {
                'event_category': 'worker',
                'event_label': 'Worker Removed',
                'value': true
            })
            this.$store.dispatch('deleteWorker', id)
        }
    }
}