<template>
	<v-dialog
		max-width="700"
		v-model="dialog">
		<v-card>
			<v-card-title class="secondary">
				Get Started Today!
				<v-spacer>
				</v-spacer>
				<v-icon @click="dialog = false">
					mdi-close
				</v-icon>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col class="text-center">
							<p class="mt-5 mb-0">
								Login or sign up for a new account to get started using the new Pool+ Browser Miner!
							</p>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col cols="auto">
							<router-link to="/login">
								<v-btn
									@click="dialog = false"
									color="primary">
									Login
								</v-btn>
							</router-link>
						</v-col>
						<v-col cols="auto">
							<router-link :to="{name: 'signUp'}">
								<v-btn
									@click="dialog = false"
									color="info">
									Get Started
								</v-btn>
							</router-link>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialog: false
		}
	},
	methods: {
		showLoginSubscribe() {
			this.$gtag.event('view', {
				'event_category': 'modals',
				'event_label': 'Login/Subscribe',
				'value': true
			})
			this.dialog = true
		}
	},
	created() {
		this.$EventBus.$on('showLoginSubscribe', () => this.showLoginSubscribe())
	}
}
</script>